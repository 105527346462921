import {
  GET_ALL_HOST_LIST,
  GET_HOST_HISTORY,
  GET_HOST_LIST,
  GET_HOST_LIVE_HISTORY,
} from "./types";

const initialstate = {
  hostList: [],
  hostHistory: [],
  hostLiveHistory: [],
  allHostList: [],
  total: 0,
};

export const hostListReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_HOST_LIST: {
      return { ...state, hostList: action.payload.data };
    }
    case GET_HOST_HISTORY: {
      return {
        ...state,
        hostHistory: action.payload.data,
      };
    }
    case GET_HOST_LIVE_HISTORY: {
      return {
        ...state,
        hostLiveHistory: action.payload,
      };
    }
    case GET_ALL_HOST_LIST: {
      return {
        ...state,
        allHostList: action.append ? [...state.allHostList, ...action.payload] : action.payload,
        total: action.total || state.total, // Ensure total updates correctly
      };
    }       
    default:
      return state;
  }
};
