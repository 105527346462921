import axios from "axios";
import { baseURL } from "../../util/Config";
import {
  GET_ALL_HOST_LIST,
  GET_HOST_HISTORY,
  GET_HOST_LIST,
  GET_HOST_LIVE_HISTORY,
} from "./types";

export const getHostList = (sDate, eDate, id) => (dispatch) => {
  axios
    .get(
      `${baseURL}agency/agencyWiseHost?agencyId=${id}&startDate=${sDate}&endDate=${eDate}`
    )
    .then((res) => {
      //   console.log("res", res.data);
      if (res.status) {
        dispatch({ type: GET_HOST_LIST, payload: res.data });
      }
    });
};

export const getHostHistory = (id, sDate, eDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}hostLiveHistory/history?startDate=${sDate}&endDate=${eDate}&hostId=${id}`
    )
    .then((res) => {
      // console.log("res.data", res.data);
      if (res.status) {
        dispatch({ type: GET_HOST_HISTORY, payload: res.data });
      }
    });
};

export const agencyHostHistory = (id, sDate, eDate) => (dispatch) => {
  axios
    .get(
      `${baseURL}hostLiveHistory/agecyHost?startDate=${sDate}&endDate=${eDate}&agencyId=${id}`
    )
    .then((res) => {
      // console.log("res.data", res.data);
      if (res.data.status) {
        console.log(res.data)
        dispatch({ type: GET_HOST_LIVE_HISTORY, payload: res.data.data });
      }
    }).catch((error) => {
      console.log('error', error.message)
    })
};

export const getAllHostList = (id, start, limit) => async (dispatch, getState) => {
  try {
    const response = await fetch(`${baseURL}bd/getBdHosts?bdId=${id}&start=${start}&limit=${limit}`);
    const data = await response.json();

    if (data.status) {
      dispatch({
        type: GET_ALL_HOST_LIST,
        payload: data.data,
        total: data.total, // Ensure total items count is stored
        append: start > 1,
      });
    }
  } catch (error) {
    console.error("Error fetching hosts:", error);
  }
};
